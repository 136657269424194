/* Values */
@value --container-position: 10px;
@value --container-zindex: 1060;
@value --container-width: 80%;
@value --container-max-with: 400px;

/* Base */
.c-notification__container {
  position: fixed;
  margin: 0 auto;
  z-index: --container-zindex;
  width: --container-width;
  max-width: --container-max-with;
}

/* Position */
.c-notification__container--top {
  composes: c-notification__container;
  top: --container-position;
  right: 0;
  left: 0;
}

.c-notification__container--top-left {
  composes: c-notification__container;
  top: --container-position;
  right: auto;
  left: --container-position;
}

.c-notification__container--top-right {
  composes: c-notification__container;
  top: --container-position;
  right: --container-position;
  left: auto;
}

.c-notification__container--bottom {
  composes: c-notification__container;
  right: 0;
  bottom: --container-position;
  left: 0;
}

.c-notification__container--bottom-left {
  composes: c-notification__container;
  right: auto;
  bottom: --container-position;
  left: --container-position;
}

.c-notification__container--bottom-right {
  composes: c-notification__container;
  right: --container-position;
  bottom: --container-position;
  left: auto;
}
